import React, { useEffect, useState } from 'react';
import KundeoversiktListe from './KundeoversiktListe';
import KundeoversiktFilter from './KundeoversiktFilter';
import api from '../../shared/lib/api';
import { filteredKundeliste, getKundeansvarlige, moveKunderInArray } from '../kundeHelper';
import { getQueryParam, setQueryParam } from '../../../../shared/lib/utils';
import { IKunde, IRadgiver } from '../../shared/lib/types';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';

const Kundeoversikt = () => {
    const queryParamKey = 'q';
    const selectedKundeansvarligParamKey = 'a';
    const [kundeliste, setKundeliste] = useState<Array<IKunde>>();
    const [kundeansvarlige, setKundeansvarlige] = useState<Array<IRadgiver>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [searchQuery, _setSearchQuery] = useState<string>(getQueryParam(queryParamKey));
    const setSearchQuery = (searchQuery: string) => {
        const q = searchQuery.trimStart();
        setQueryParam(queryParamKey, q);
        _setSearchQuery(q);
    };
    const [selectedKundeansvarligBrukerId, _setSelectedKundeansvarligBrukerId] = useState<string>(getQueryParam(selectedKundeansvarligParamKey));
    const setSelectedKundeansvarligBrukerId = (kundeansvarligBrukerId: string) => {
        setQueryParam(selectedKundeansvarligParamKey, kundeansvarligBrukerId);
        _setSelectedKundeansvarligBrukerId(kundeansvarligBrukerId);
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then(async (bruker) => {
                    await api.getKunder().then((kunder) => {
                        const egetRegnskapsKunde = kunder.find((kunde) => kunde.egetForetak);
                        const filteredKunder = moveKunderInArray(egetRegnskapsKunde, kunder);
                        const kundeAnsvarlig = getKundeansvarlige(filteredKunder);
                        const isEgetforetakKundeAnsvarlig = kundeAnsvarlig.some((item) => item.brukerId === bruker.brukerId);
                        if (isEgetforetakKundeAnsvarlig) {
                            setSelectedKundeansvarligBrukerId(bruker.brukerId);
                        }

                        setKundeliste(filteredKunder);
                        setKundeansvarlige(kundeAnsvarlig);
                    });
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Kundeoversikt';
        })();
    }, []);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div className="kundeoversikt">
            <div className="page-header">
                <div className="container">
                    <h1>Kundeoversikt</h1>
                </div>
            </div>
            <div className="page">
                <div className="container">
                    <KundeoversiktFilter
                        searchQuery={searchQuery}
                        selectedKundeansvarligBrukerId={selectedKundeansvarligBrukerId}
                        kundeansvarlige={kundeansvarlige}
                        showAddKundeBtn={true}
                        onSearchQueryChange={setSearchQuery}
                        onSelectedKundeansvarligChange={setSelectedKundeansvarligBrukerId}
                    />

                    <div className="row">
                        <div className="col-12">
                            {kundeliste && (
                                <KundeoversiktListe
                                    kunder={filteredKundeliste(kundeliste, selectedKundeansvarligBrukerId, searchQuery) as Array<IKunde>}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kundeoversikt;
