import React from 'react';
import { Link } from 'react-router-dom';

import SelectInput, { ISelectInputOption } from '../../../form/SelectInput';
import TextInput from '../../../form/TextInput';

import { InputElementEnum } from '../../../../shared/lib/types';
import { IRadgiver } from '../../shared/lib/types';

export interface IKundeoversiktFilter {
    searchQuery?: string;
    selectedKundeansvarligBrukerId?: string;
    kundeansvarlige?: Array<IRadgiver>;
    showAddKundeBtn?: boolean;
    onSearchQueryChange(value: string): void;
    onSelectedKundeansvarligChange(value: string): void;
}

const KundeoversiktFilter = (props: IKundeoversiktFilter) => {
    function radgivereToSelectOptions() {
        return (
            (props.kundeansvarlige?.map((radgiver) => {
                return {
                    text: `${radgiver.fornavn} ${radgiver.etternavn}`,
                    value: radgiver.brukerId,
                };
            }) as Array<ISelectInputOption>)
                // Remove duplicates
                ?.filter((x: ISelectInputOption, i: number, a: Array<ISelectInputOption>) => a.map((option) => option.value).indexOf(x.value) === i)
                // Sort alphabetically
                ?.sort((a: ISelectInputOption, b: ISelectInputOption) => a.text.localeCompare(b.text)) ?? []
        );
    }

    return (
        <div className="kundeoversikt__filter">
            <div className="d-md-flex">
                    <TextInput
                        label="SØK"
                        name="search-input"
                        value={props.searchQuery}
                        width="mediumtext"
                        type={InputElementEnum.search}
                        placeholder="Kundenavn, org.nummer, kundeansvarlig..."
                        focus=""
                        onInputChange={(name, value) => {
                            props.onSearchQueryChange(value);
                        }}
                    />
 
                {props.kundeansvarlige && !!props.kundeansvarlige?.length && (
                    <SelectInput
                        label="KUNDEANSVARLIG"
                        firstOptionText="Alle"
                        createFirstOption={true}
                        name="kundeansvarlig-select"
                        value={props.selectedKundeansvarligBrukerId}
                        options={radgivereToSelectOptions()}
                        focus=""
                        type={InputElementEnum.select}
                        onInputChange={(name, value) => {
                            props.onSelectedKundeansvarligChange(value);
                        }}
                    />
                )}
                {props.showAddKundeBtn && (
                    <>
                        <div className="flex-grow-1"></div>
                        <div className="d-md-flex align-items-end justify-content-end">
                            <div className="hstack align-items-end">
                                <Link to="/endre-kunde-ansvarlig" className="btn btn--secondary">
                                    Endre kundeansvarlig
                                </Link>
                                <Link to="/legg-til-kunde" className="btn btn--primary">
                                    Legg til ny kunde
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default KundeoversiktFilter;
