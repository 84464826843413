import React from 'react';
import { Link } from 'react-router-dom';
import { removeSpaces } from '../../../../shared/lib/utils';
import { IKunde } from '../../shared/lib/types';

type TProps = {
    kunder: Array<IKunde>;
};


const KundeoversiktListe = (props: TProps) => {
    return (
        <div className="kundeliste">
            <table className="table--kundeliste">
                <thead>
                    <tr>
                        <td>Kundenavn</td>
                        <td>Org.nummer</td>
                        <td>Kundeansvarlig</td>
                        <td>
                            <span className="sr-only">Funksjoner</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {props.kunder?.map((kunde) => {
                        return (
                            <tr key={kunde.id} className={`${kunde.egetForetak ? 'tr-border' : ''}`}>
                                <td className="table--cell">
                                    <span className={`icon ${kunde.egetForetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                                    <Link to={`/kunder/${removeSpaces(kunde.id)}/kundeside`} className="table-cell__title">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: `${kunde.navn}${kunde.egetForetak ? ' (Eget foretak)' : ''}`,
                                            }}
                                        ></span>
                                        {kunde.harBegrensetKundesynlighet && <span className="icon icon-lock-open" />}
                                    </Link>
                                </td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: kunde.organisasjonsnummer,
                                    }}
                                ></td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: `${kunde.kundeansvarlig.fornavn} ${kunde.kundeansvarlig.etternavn}`,
                                    }}
                                ></td>
                                <td>
                                    <div className="table__actions">
                                        <Link to={`/kunder/${removeSpaces(kunde.id)}/kundeside`} className="btn btn--secondary btn--sm">
                                            {kunde.egetForetak ? 'Eget foretak' : 'Kundeside'}{' '}
                                        </Link>
                                        <Link to={`/kunder/${removeSpaces(kunde.id)}/velg-veileder?t=1`} className="btn btn--secondary btn--sm">
                                            Start veileder
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default KundeoversiktListe;
